:root { 
  --accentblue: #3585d6;
  --accentbluedark: #2070c0;
  --accentblueextralight: #b9d6f3;
  --accentbluelight: #63a9ef;
  --accentgreenlight: #bcefb4;
  --accentred: #ef233c;
  --accentredlight: #f35f71;
  --accentyellow: #efce22;
  --accentyellowlight: #f5e8a7;
  --black: #000000;
  --contentextralight: #dde4eb;
  --contentlight: #bcc5ce;
  --contentprimary: #2b3642;
  --contentsecondary: #677380;
  --domdop: #f4f7f9;
  --domextralight: #ebf0f5;
  --domlight: #d0dae4;
  --dommain: #f9fbfc;
  --domsecondary: #e5ebf1;
  --domtruewhite: #ffffff;

  --font-size-l: calc(12px + 6 * (100vw / 1320));
  --font-size-m: calc(12px + 4 * (100vw / 1320));
  --font-size-s: calc(12px + 2 * (100vw / 1320));
  --font-size-xl: calc(12px + 8 * (100vw / 1320));
  --font-size-xs: calc(12px * (100vw / 1320));
  --font-size-xxl: calc(12px + 12 * (100vw / 1320));
  --font-size-xxxl: calc(12px + 22 * (100vw / 1320));
 
  /*--font-size-l: 18px;*/
  /*--font-size-m: 16px;*/
  /*--font-size-s: 14px;*/
  /*--font-size-xl: 20px;*/
  /*--font-size-xs: 12px;*/
  /*--font-size-xxl: 24px;*/
  /*--font-size-xxxl: 34px;*/
  /*--font-size-t: calc(12px + 12 * (100vw / 1320));*/
 
  --font-family-ibm_plex_sans: "IBM Plex Sans", Helvetica;
}
