.blog-page-price-table {
  align-items: flex-start;
  background-color: var(--dommain);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 1716px;
  position: relative;
  width: 1440px;
}

