.main-page {
  align-items: center;
  background-color: var(--dommain);
  border: 1px none;
  display: flex;
  flex-direction: column;
  /*height: 3297px;*/
  position: relative;
  /*width: 1440px;*/
  /*max-width: 1440px;*/
}

