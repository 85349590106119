.request-popup-enabled {
  align-items: center;
  background-color: var(--dommain);
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 48px;
  height: 1280px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

