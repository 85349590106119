.atoms-molecules {
  align-items: flex-start;
  background-color: var(--domtruewhite);
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 108px;
  height: 2721px;
  padding: 79px 67px;
  width: 1723px;
}

